import { Box, Grid2 as Grid, Typography, Button } from "@mui/material";
import SynclisLogo from "../../../assets/images/logo.svg";
import FixedWidthContainer from "../../reuseable/FixedWidthContainer/FixedWidthContainer";
import ProfitTobias from "../../../assets/images/profit-tobias.png";

export default function InformationPage() {
  const handleClick = () => {
    window.location = "http://localhost:3000/vsl";
  };
  return (
    <FixedWidthContainer width="800px">
      <Box minWidth="400px" p="2em">
        <Box textAlign="left" mb="1em" p="2em">
          <img src={SynclisLogo} width="200px" />

          <wistia-player media-id="8y7mvi4ska"></wistia-player>

          <p>
            Did you know you can turn your followers into lifelong customers?
            Best of all you can do this without having to do:
            <br />
            <br />
            <div>- Affiliate marketing,</div>
            <div>- Brand deals,</div>
            <div>- Subscriptions</div>
            <div>- Or Spending money on ads</div>
          </p>

          <p></p>

          <p>How does it work? Here’s a rough idea:</p>

          <ol>
            <li style={{ marginBottom: "0.5em" }}>
              We work together with you to build a service or product. If you
              don’t have one, don’t worry! We can work together to build one for
              you. In most cases, what your following wants is access to you!
            </li>
            <li style={{ marginBottom: "0.5em" }}>
              We will build a sales funnel and install a high performing sales
              team in the funnel. We will also build your marketing strategy to
              promote your product or service for the best result.
            </li>
            <li style={{ marginBottom: "0.5em" }}>
              Then, we ask you to promote the product or service to your
              audience. We will help you to make it feel exclusive and urgent to
              your following.
            </li>
          </ol>

          <p>
            After our first “launch“ together, we can continue to “launch“ as
            many times as you’re able to.
          </p>

          <p>See this as a supplemental stream of income for you.</p>
          <p>
            To get started, there is no cost to you, if you already have a
            product or service.{" "}
          </p>
          <p>
            If you don't have a product or service, there will be a cost, but{" "}
            <b> this will be all done for you.</b>
          </p>
          <p>
            We have seen great success from this model, and have helped our
            clients earn more on top of what they do from this strategy.
          </p>

          <Button variant="contained" onClick={handleClick}>
            See if you qualify
          </Button>
        </Box>
        <Box backgroundColor="#EFEFEF" padding="2em">
          <Grid container spacing={4}>
            <Grid size={6}>
              <img
                src="https://synclis.s3.us-east-2.amazonaws.com/zoom-tobias.png"
                style={{ width: "100%", marginBottom: "1em" }}
              />

              <img src={ProfitTobias} style={{ width: "100%" }} />
            </Grid>
            <Grid size={6}>
              <Typography
                variant="h6"
                fontFamily='"Nunito", serif;'
                gutterBottom
                sx={{
                  textAlign: "center",
                  lineHeight: "32px",
                  marginBottom: "2em",
                  textTransform: "uppercase",
                  color: "#555",
                  letterSpacing: "2px",
                }}
              >
                Proven Results
              </Typography>
              <Box lineHeight="28px" mb="1em">
                We recently have helped{" "}
                <a href="https://www.instagram.com/tobiaskrick">Tobias</a> a
                volleyball influencer grow his following and connect more
                closely with his following. We did this by helping him create an
                offer to provide online group coaching lessons to his following
                without spending anything on ads.
              </Box>
              <Box lineHeight="28px">
                Even with his busy schedule preparing for the next Olympics, he
                is now able to travel and practice with his team members
                globally. We were able to earn over $75K in less than 90 days.
                This was the result from Jan 1 to Mar 30, 2024.
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box padding="2em">
          <Grid container spacing={4}>
            <Grid size={6}>
              Or even Vanessa, a beauty content creator who had a following of
              80K. She was working at a desk job and posting corporate sponsored
              content on her Social Media. She is now a style consultant who
              made $11K in our first month working together. She is now earning
              over $30K after dialing her offer, and she works on her content
              full time.
            </Grid>
            <Grid size={6}>
              <img
                src="https://synclis.s3.us-east-2.amazonaws.com/Screenshot+2024-12-13+at+4.43.19%E2%80%AFPM.png"
                style={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt="2em">
          <Button
            variant="contained"
            onClick={() =>
              (window.location = "https://calendly.com/imaginate-io/30min")
            }
          >
            Book a call with us
          </Button>
        </Box>
      </Box>
    </FixedWidthContainer>
  );
}
