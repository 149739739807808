import { Box, Typography, Button, Grid2 as Grid } from "@mui/material";
import FixedWidthContainer from "../../../reuseable/FixedWidthContainer/FixedWidthContainer";
import { MechanicalCounter } from "mechanical-counter";
import "./Homepage.css";
import { useEffect, useState } from "react";
import "./particles";

export default function Homepage() {
  const messages = [
    {
      prefix: "+",
      metric: "419.27",
      suffix: "%",
      message: "Average return on investment",
    },
    {
      prefix: "$",
      metric: "16",
      suffix: "M",
      message: "Ads Managed",
    },
    {
      prefix: "+",
      metric: "184.31",
      suffix: "%",
      message: "Return On Ad Spend",
    },
    {
      prefix: "",
      metric: "100",
      suffix: "%",
      message: "customer satisfaction",
    },
  ];
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      if (messageIndex + 1 < messages.length) {
        setMessageIndex(messageIndex + 1);
      } else {
        setMessageIndex(0);
      }
    }, 3000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [messageIndex]);

  useEffect(() => {
    const load = () => {
      window.particlesJS(
        "particles-js",

        {
          particles: {
            number: {
              value: 80,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: "#ffffff",
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 5,
              random: true,
              anim: {
                enable: false,
                speed: 20,
                size_min: 10,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 6,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
              onclick: {
                enable: true,
                mode: "push",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: {
                distance: 180,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        }
      );
    };

    setTimeout(() => {
      load();
    }, 1);
  }, []);

  const handleSalesCall = () => {
    window.location = "https://calendly.com/imaginate-io/30min";
  };

  return (
    <>
      <Box className="syn-homepage-hero">
        <Box backgroundColor="black" width="100%">
          <div id="particles-js" style={{ height: "100%" }}></div>
          <Box
            position="absolute"
            top="50%"
            left="50%"
            marginLeft="-48px"
            zIndex="12"
            color="white"
            style={{ transform: "scale(3) translate3d(0,0,0)" }}
          >
            <Box
              fontFamily='"Nunito", serif;'
              letterSpacing="2px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              {messages[messageIndex].prefix}
              <MechanicalCounter
                text={
                  messages[messageIndex] ? messages[messageIndex].metric : "0"
                }
                style={{ display: "inline-block" }}
              />
              {messages[messageIndex].suffix}
            </Box>
            <Box fontSize="5px" textAlign="center">
              {messages[messageIndex].message}
            </Box>
            <Button
              style={{ transform: "scale(0.3)" }}
              onClick={handleSalesCall}
              variant="outlined"
            >
              Book Now
            </Button>
          </Box>
        </Box>
      </Box>

      <Box>
        <Box p="4em">
          <FixedWidthContainer>
            <Typography
              variant="h6"
              fontFamily='"Nunito", serif;'
              gutterBottom
              sx={{
                lineHeight: "32px",
                marginBottom: "2em",
                textTransform: "uppercase",
                color: "#555",
                letterSpacing: "2px",
              }}
            >
              What we do
            </Typography>
            <Typography sx={{ lineHeight: "32px" }}>
              <Box mb="1em">
                We specialize in helping content creators, personal brands and
                coaches start earning revenue from their followings within 30
                days.
                <br />
                We see your social media page as a building where your followers
                go in and out of. <br />
                Think about what your building currently looks like? <br />
                <br />
                <p>
                  Is it a classroom where people stop by to learn things? <br />
                  Is it a gym where people tune in to get in the best shape of
                  their lives? <br />
                  Or... is it a giant mall where people can't buy anything?
                </p>
                <br />
                <p>
                  There’s the problem, 98% of influencers or content creators
                  have an empty building where their followers see large
                  billboards with other brands. A giant mall where there are no
                  transactions. We help you set up stores, classrooms and/or
                  gyms in your building to help you and your followers get the
                  most out of it.
                </p>
              </Box>
            </Typography>
          </FixedWidthContainer>
        </Box>

        <Box p="4em" backgroundColor="#f2f2f2">
          <FixedWidthContainer>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box p="1em">
                  <Typography
                    variant="h6"
                    fontFamily='"Nunito", serif;'
                    sx={{
                      lineHeight: "32px",
                      textTransform: "uppercase",
                      color: "#555",
                      marginBottom: "1em",
                      letterSpacing: "2px",
                    }}
                  >
                    Academy
                  </Typography>
                  <Box lineHeight="32px" marginBottom="1em" color="#333">
                    Invest in yourself. Unlock the power to turn your creative
                    ideas into reality. Our courses will help you learn the
                    skills you need to be successful on the internet.
                  </Box>
                  <Button
                    style={{ width: { xs: "100%", s: "initial" } }}
                    onClick={() => {
                      window.location =
                        "https://www.skool.com/imaginate-ad-agency-9357";
                    }}
                    variant="outlined"
                  >
                    Register
                  </Button>
                </Box>
              </Grid>
              {/* <Grid size={{ xs: 12, md: 4 }}>
                <Box p="1em">
                  <Typography
                    variant="h6"
                    fontFamily='"Nunito", serif;'
                    sx={{
                      lineHeight: "32px",
                      textTransform: "uppercase",
                      color: "#555",
                      marginBottom: "1em",
                      letterSpacing: "2px",
                    }}
                  >
                    SaaS
                  </Typography>
                  <Box lineHeight="32px" marginBottom="1em" color="#333">
                    We carry a suite of powerful online software tools to
                    support an entrepeneur along their journey in growth
                    marketing.
                  </Box>
                  <Button
                    style={{ width: { xs: "100%", s: "initial" } }}
                    onClick={handleSalesCall}
                    variant="outlined"
                  >
                    LOG IN
                  </Button>
                </Box>
              </Grid> */}
              <Grid size={{ xs: 12, md: 6 }}>
                <Box p="1em">
                  <Typography
                    variant="h6"
                    fontFamily='"Nunito", serif;'
                    sx={{
                      lineHeight: "32px",
                      textTransform: "uppercase",
                      color: "#555",
                      marginBottom: "1em",
                      letterSpacing: "2px",
                    }}
                  >
                    Consultation
                  </Typography>
                  <Box lineHeight="32px" marginBottom="1em" color="#333">
                    Invest in your platform. Learn more about what our team can
                    do for you. Please only consult with us if you're a
                    <b> content creator</b> looking to partner with us or an
                    <b> online business</b> looking to grow with advertising.
                  </Box>
                  <Button
                    style={{ width: { xs: "100%", s: "initial" } }}
                    onClick={handleSalesCall}
                    variant="contained"
                  >
                    Book Now
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </FixedWidthContainer>
        </Box>
      </Box>

      <Box>
        <Box p="4em">
          <FixedWidthContainer>
            <Typography
              variant="h6"
              fontFamily='"Nunito", serif;'
              gutterBottom
              sx={{
                lineHeight: "32px",
                marginBottom: "2em",
                textTransform: "uppercase",
                color: "#555",
                letterSpacing: "2px",
              }}
            >
              Keep in touch
            </Typography>
            <Box
              lineHeight="32px"
              marginBottom="1em"
              color="#333"
              maxWidth="500px"
            >
              Stay connected with us. Enter your email to discover more about
              our services and updates. Or, sign up to stay updated with
              cutting-edge and innovative marketing ideas.
            </Box>

            <Box ml="-1em">
              <div class="klaviyo-form-YcR8T2"></div>
            </Box>
          </FixedWidthContainer>
        </Box>
      </Box>
    </>
  );
}
